.tags-profile .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
	padding: 4px;
	height: 30px !important;
}

.tags-profile .MuiChip-deleteIcon {
	color: #ffffff;
}

.tags-profile .MuiChip-deleteIcon:hover {
	color: #ffffff !important;
	opacity: 0.8 !important;
}
