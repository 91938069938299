.folder-item-pseudo-icon-button {
	border-radius: 100px;
	backdrop-filter: blur(10px);
	background-color: rgba(255, 255, 255, 0.1);
	width: 25px;
	height: 25px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.folder-item-pseudo-icon-button:hover {
	backdrop-filter: blur(10px);
	background-color: rgba(0, 156, 204, 0.1);
}
