.orb {
	z-index: -10;
	width: 180px;
	height: 180px;
	animation: shapeColorShift 7s infinite;
	filter: blur(12px);
	background: linear-gradient(-45deg, #08aeea, #ac60f7, #fff, #0086ff, #27b2ff, #fff, #ac60f7, #08aeea);
	background-size: 500% 800%;
}
.orb:before {
	content: '';
	display: block;
	position: absolute;
	top: -10px;
	right: -35px;
	bottom: -35px;
	left: -10px;
	background: inherit;
	filter: blur(30px);
	opacity: 0.6;
	animation: shapeColorShift 7s infinite;
}
@keyframes shapeColorShift {
	0% {
		border-radius: 50vw 50vw 50vw 50vw / 50vw 50vw 50vw 50vw;
		background-position: 0% 0%;
	}
	50% {
		border-radius: 20vw 40vw 20vw 40vw / 20vw 40vw 20vw 40vw;
		background-position: 100% 100%;
	}
	100% {
		border-radius: 50vw 50vw 50vw 50vw / 50vw 50vw 50vw 50vw;
		background-position: 0% 0%;
		transform: rotate(1turn);
	}
}
