.title-analysis-card {
	color: rgb(73, 73, 73);
	font-family: 'Poppins', sans-serif;
	font-size: 1.4em;
	font-weight: 200;
	cursor: default;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 25px;
}

.title-analysis-card-tags {
	font-family: 'Poppins', sans-serif;
	font-size: 1.4em;
	font-weight: 200;
	cursor: default;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-left: 8px;
}
