.link-breadcrumbs {
	text-decoration: none !important;
	opacity: 0.7 !important;
}

.link-breadcrumbs:hover {
	text-decoration: none !important;
	opacity: 1 !important;
	color: #1d8cf8 !important;
}
