/* ####### align ####### */
.d-flex {
	display: flex !important;
}
.jc-center {
	justify-content: center !important;
}
.jc-flex-end {
	justify-content: flex-end !important;
}
.cursor-default {
	cursor: default !important;
}
.ai-center {
	align-items: center;
}
.ta-center {
	text-align: center;
}
.jc-space-between {
	justify-content: space-between;
}
.jc-right {
	justify-content: right;
}
/*margin-top*/
.mt-1 {
	margin-top: 1em;
}
.mt-1i {
	margin-top: 1em !important;
}
.mt-2ni {
	margin-top: -2em !important;
}
.mt-autoi {
	margin-top: auto !important;
}
/*margin-bottom*/
.mb-autoi {
	margin-bottom: auto !important;
}
.mb-2i {
	margin-bottom: 2em !important;
}
.mb-1i {
	margin-bottom: 1em !important;
}
.mb-3i {
	margin-bottom: 3em !important;
}
.mb-3-1i {
	margin-bottom: 3.5em !important;
}
/*margin-left*/
.ml-1 {
	margin-left: 1em;
}
.ml-2i {
	margin-left: 2em !important;
}
.ml-3i {
	margin-left: 3em !important;
}
.ml-6i {
	margin-left: 6em !important;
}
/*margin-right*/
.mr-1 {
	margin-right: 1em;
}
/*margin-etc*/
.mh-auto {
	margin-left: auto;
	margin-right: auto;
}
/* padding */
.no-padding {
	padding: 0 !important;
}

/* ####### font ####### */
.fs-0-8i {
	font-size: 0.8em !important;
}
.fs-0-9i {
	font-size: 0.9em !important;
}
.fs-1-0-5i {
	font-size: 1.05em !important;
}
.fs-1-5 {
	font-size: 1.5em;
}
.bold {
	font-weight: bold;
}

/*fazer pra outras fontes também, colocando o weight*/
.ff-montserrat {
	font-family: 'Montserrat', sans-serif;
}

/* ####### size ####### */
.width-100pc {
	width: 100%;
}

/* ####### general styling ####### */
:root {
	--card-box-shadow: 0px 0px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 1px 5px 1px rgb(0 0 0 / 10%) !important;
}
