body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/*⌄⌄⌄⌄ ESCONDER SETA DO INPUT NUMBER ⌄⌄⌄⌄*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
/*^^^^ ESCONDER SETA DO INPUT NUMBER ^^^^*/

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
	background-color: #f5f6fa;
}

.active.btn {
	z-index: 0 !important;
}

.page-content {
	padding-left: 6em;
	padding-right: 3em;
	padding-bottom: 0.4em;
}

.sharing-page-content {
	padding-left: 3em;
	padding-right: 3em;
	padding-bottom: 0.4em;
}

.page-1-content {
	padding: 1em 3em;
}

.page-2-content {
	padding: 3em 3em;
}

.divider-thin-blue {
	background-color: var(--blue) !important;
	width: 100%;
	margin: 0 !important;
	height: 1px;
}

.divider-thin-light-green {
	background-color: var(--lightGreen) !important;
	width: 100%;
	margin: 0 !important;
	height: 1px;
}

.divider-thin-default {
	background-color: var(--lightGrey) !important;
	width: 100%;
	margin: 0;
	height: 1px;
}

.divider-thin-default-lighter {
	background-color: var(--white) !important;
	width: 100%;
	margin: 0;
	height: 1px;
}

.simple-modal-center-screen {
	padding: 2em !important;
}
#dialog-app {
	height: 80% !important;
}

/* BUTTON */
.primary-button-app {
	color: var(--white) !important;
	background-color: var(--blue) !important;
	border-radius: 5px;
	outline: none !important;
}
.primary-button-app:hover {
	background-color: var(--lightBlue) !important;
}

.primary-round-button-app {
	color: var(--white) !important;
	background-color: var(--blue) !important;
	border-radius: 100px !important;
	outline: none !important;
}
.primary-round-button-app:hover {
	opacity: 0.8 !important;
	background-color: var(--lightBlue) !important;
}
.primary-round-button-app:disabled {
	background-color: transparent !important;
	color: #9a9a9a !important;
}

.primary-round-button-app-profile {
	color: var(--white) !important;
	background-color: var(--purple) !important;
	border-radius: 100px !important;
	outline: none !important;
}
.primary-round-button-app-profile:hover {
	opacity: 0.8 !important;
	background-color: var(--purple) !important;
}
.primary-round-button-app-profile:disabled {
	background-color: transparent !important;
	color: #9a9a9a !important;
}

.primary-round-button-app-group {
	color: var(--white) !important;
	background-color: #d41993 !important;
	border-radius: 100px !important;
	outline: none !important;
}
.primary-round-button-app-group:hover {
	opacity: 0.8 !important;
	background-color: #d41993 !important;
}
.primary-round-button-app-group:disabled {
	background-color: transparent !important;
	color: #9a9a9a !important;
}

.primary-round-button-app-report {
	color: var(--white) !important;
	background-color: #18c1c4 !important;
	border-radius: 100px !important;
	outline: none !important;
}
.primary-round-button-app-report:hover {
	opacity: 0.8 !important;
	background-color: #18c1c4 !important;
}
.primary-round-button-app-report:disabled {
	background-color: transparent !important;
	color: #9a9a9a !important;
}

.primary-round-button-app-account-management {
	color: var(--white) !important;
	background-color: #4fddf0 !important;
	border-radius: 100px !important;
	outline: none !important;
}
.primary-round-button-app-account-management:hover {
	opacity: 0.8 !important;
	background-color: #4fddf0 !important;
}
.primary-round-button-app-account-management:disabled {
	background-color: transparent !important;
	color: #9a9a9a !important;
}

.image-button {
	color: #1d8cf8 !important;
	background-color: transparent !important;
}
.image-button:hover {
	background-color: #1d8cf8 !important;
	color: whitesmoke !important;
}

.image-button-header {
	padding: 1.5em !important;
	/* padding-left: 3em !important; */
	/* padding-right: 3em !important; */
	background-color: transparent !important;
	border: 1px solid rgba(29, 138, 248, 10%) !important;
	/* border-radius: 100px !important; */
}
.image-button-header:hover {
	background-color: rgba(29, 138, 248, 10%) !important;
}

.secondary-button-app {
	color: var(--white) !important;
	background-image: linear-gradient(90deg, var(--blue), var(--lightGreen)) !important;
	border-radius: 5px !important;
	outline: none !important;
}
.secondary-button-app:hover {
	background-image: linear-gradient(90deg, var(--green1), var(--lightGreen)) !important;
}

.secondary-round-button-app {
	color: var(--white) !important;
	/* background-image: linear-gradient(90deg, var(--blue), var(--lightGreen)) !important; */
	background-color: #02d9af !important;
	border-radius: 100px !important;
	outline: none !important;
}
.secondary-round-button-app:hover {
	background-color: #78ebd4 !important;
}
.secondary-round-button-app:disabled {
	background-image: none !important;
	color: #9a9a9a !important;
}

.secondary-round-button-profile-app {
	color: var(--white) !important;
	background-image: linear-gradient(90deg, var(--purple), var(--lightGreen)) !important;
	border-radius: 100px !important;
	outline: none !important;
}
.secondary-round-button-profile-app:hover {
	background-image: linear-gradient(90deg, var(--green1), var(--lightGreen)) !important;
}
.secondary-round-button-profile-app:disabled {
	background-image: none !important;
	color: #9a9a9a !important;
}

/* FIX LAYOUT */

.fix-icon-margin-bottom-negative {
	margin-bottom: -0.25em;
}

.fix-icon-margin-bottom-negative-1 {
	margin-bottom: -0.15em;
}
