.editBox {
	--b: 3px; /* thickness of the border */
	--c: #1d8cf8; /* color of the border */
	--w: 22px; /* width of border */
	--r: 22px; /* radius */

	position: relative;
	/* background-color: #fff; */
}
.editBox::before {
	content: '';
	position: absolute;
	inset: 0;
	background: var(--c, #1d8cf8);
	padding: var(--b);
	border-radius: var(--r);
	-webkit-mask: linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50% var(--b) / calc(100% - 2 * var(--w)) 100% repeat-y,
		linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100% calc(100% - 2 * var(--w)) repeat-x,
		linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
	-webkit-mask-composite: destination-out;
	mask-composite: exclude;
}
