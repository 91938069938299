.input-container-comparative-bar-item > div {
	box-shadow: var(--card-box-shadow) !important;
	background-color: var(--white) !important;
	padding-left: 0.8em !important;
	padding-right: 0.5em !important;
	border-radius: 20px !important;
}

.input-container-comparative-bar-item > label.MuiInputLabel-shrink {
	transform: translate(16px, -10px) scale(0.75);
	width: 147%;
	height: 2em;
}

.input-container-comparative-bar-item > label.MuiFormLabel-root {
	overflow: visible;
}

.input-container-comparative-bar-item > .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
	padding: 0px;
}

.input-container-comparative-bar-item > label {
	width: 100%;
}
