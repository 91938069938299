.content-report {
	padding: 2em;
	border-top: 5px solid #3b5998;
}

.content-report-header {
	display: flex;
}

.text-report-header {
	margin-top: 0.15em;
	color: black;
	font-size: 2.5em;
	margin-left: 0.3em;
}
