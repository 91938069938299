.react-grid-item.react-grid-placeholder {
	background: rgba(250, 250, 250, 0.9);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	border-radius: 25px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.react-resizable-handle {
	background-image: none !important;
}

.react-grid-item > .react-resizable-handle::after {
	border: none !important;
}
