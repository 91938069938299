.report {
	background-color: white;
	text-align: center;
	border-radius: 20px;
	width: 80%;
	margin: auto;
	margin-top: 2em;
	margin-bottom: 2em;
}

@media print {
	.report-print {
		width: 100% !important;
		margin: 0% !important;
	}

	.break-print {
		page-break-after: always;
	}

	.hide-print {
		visibility: hidden;
	}
}
