.network-info-link-abrir-perfil {
	color: #0f5dab !important;
	font-size: 13px !important;
	font-weight: bold !important;
	text-decoration: none !important;
}

.network-info-link-abrir-perfil:hover {
	opacity: 0.8;
}
