@media only screen and (min-width: 1001px) {
	.negative-margin-body-analysis-grafico-sensibilidade {
		margin-top: -45px;
	}
}

.nav-item-custom-style {
	text-align: center !important;
	cursor: pointer !important;
	border-radius: 25px;
}

.nav-link-custom-style {
	height: 2em;
	justify-content: center !important;
	font-size: 0.9em !important;
	color: var(--grey) !important;
	border-radius: 25px;
}

.nav-link-custom-style:hover {
	background-color: var(--lightGrey);
	border-radius: 25px;
}

.return-arrow {
	float: left;
	font-size: 1.3125rem;
	font-weight: 600;
	line-height: 1;
	color: rgba(0, 0, 0, 0.5);
	text-shadow: none;
	opacity: 0.5;
}
.return-arrow:hover {
	color: rgba(0, 0, 0, 0.6);
	text-decoration: none;
}
.return-arrow:not(:disabled):not(.disabled):hover,
.return-arrow:not(:disabled):not(.disabled):focus {
	opacity: 0.75;
}

button.return-arrow {
	padding: 0 !important;
	background-color: transparent !important;
	border: 0 !important;
	appearance: none !important;
	outline: none !important;
}

.custom-collapse-header:hover > div {
	color: #094581 !important;
}
