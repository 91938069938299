.tab-container-list-items-tab {
	margin-top: 5em !important;
}

.tab-container-list-items-tab > * .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary.tab-item-list-items-tab {
	display: flex !important;
	flex-direction: row !important;
	justify-content: left !important;
	padding-left: 1.8em !important;
	white-space: nowrap !important;
	color: rgba(126, 126, 126, 60%) !important;
	fill: rgba(126, 126, 126, 60%) !important;
	font-family: 'Poppins' !important;
	font-weight: 600 !important;
}

.tab-container-list-items-tab > * .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary.Mui-selected.tab-item-list-items-tab {
	color: var(--blue) !important;
	fill: var(--blue) !important;
}

.tab-container-list-items-tab > * .MuiTabs-indicator {
	left: 0em !important;
	width: 0.1em !important;
	/* height: 0.1em !important; */
	background-color: var(--blue) !important;
}

.tab-container-list-items-tab > * .MuiTab-labelIcon {
	min-height: 4em !important;
	height: 4em !important;
	min-width: 400px !important;
	color: var(--blue) !important;
}

.icon-container-list-items-tab {
	margin-bottom: 3px !important;
	width: 3.5em !important;
	height: 3em !important;
	display: flex !important;
	align-items: center !important;
}
