.scrollableDiv {
	height: 750px;
	width: 50%;
	margin: auto;
}
::-webkit-scrollbar {
	width: 8px;
	height: 12px;
}
::-webkit-scrollbar-thumb {
	background: linear-gradient(13deg, #cfcfcfc5 14%, #cfcfcfc5 14%);
	border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(13deg, #cfcfcf 14%, #cfcfcf 64%);
}
::-webkit-scrollbar-track {
	display: none;
}
