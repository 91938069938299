.button-stepper {
	background-color: var(--blue) !important;
	color: var(--white) !important;
	border-radius: 100% !important;
	height: 40px;
	width: 40px;
	min-width: auto !important;
	pointer-events: none;
}

.button-stepper-profile {
	background-color: #ac60f7 !important;
	color: var(--white) !important;
	border-radius: 100% !important;
	height: 40px;
	width: 40px;
	min-width: auto !important;
	pointer-events: none;
}

.button-stepper-profile:disabled {
	background-color: #d4d4d4 !important;
}

.button-stepper-group {
	background-color: #d41993 !important;
	color: var(--white) !important;
	border-radius: 100% !important;
	height: 40px;
	width: 40px;
	min-width: auto !important;
	pointer-events: none;
}

.button-stepper-group:disabled {
	background-color: #d4d4d4 !important;
}

.button-stepper-report {
	background-color: #18c1c4 !important;
	color: var(--white) !important;
	border-radius: 100% !important;
	height: 40px;
	width: 40px;
	min-width: auto !important;
	pointer-events: none;
}

.button-stepper-report:disabled {
	background-color: #d4d4d4 !important;
}

.button-stepper > span {
	margin-bottom: -0.1em;
}

.button-stepper:disabled {
	background-color: #d4d4d4 !important;
}

.button-stepper:hover {
	opacity: 0.8;
}
