.search-outlined-input-sentiment-analysis {
	width: 100%;
	border-radius: 8px !important;
}

.search-outlined-input-sentiment-analysis > input {
	padding: 10px 8px;
	font-family: 'Poppins';
	font-weight: 300;
	/* letter-spacing: px */
}

.outline-box-shadow-focus-none:focus {
	outline: none;
	box-shadow: none;
}

.button-new-card-sentiment-analysis {
	font-size: 0.9em !important;
	width: 7em !important;
	height: 2.5em !important;
	padding: 10px !important;
	border-radius: 100px !important;
	color: var(--white) !important;
	background-image: linear-gradient(45deg, var(--blue), var(--lightGreen)) !important;
}

.button-new-group-sentiment-analysis {
	padding: 10px !important;
	border-radius: 100px !important;
	color: var(--white) !important;
	background-color: var(--blue) !important;
}
