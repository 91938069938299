.MuiButton-root.Mui-disabled {
	background-color: 'transparent' !important;
}

.MuiCard-root.MuiPaper-elevation1 {
	box-shadow: var(--card-box-shadow);
}

.MuiSelect-select:focus {
	background-color: unset !important;
}

.adjust-autocomplete .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
	padding-top: 1px !important;
	padding-bottom: 1px !important;
}
