.title-report {
	color: black;
	font-size: 4em;
}

.info-report {
	color: black;
}

.info-header-report {
	margin-top: 7em;
	margin-bottom: 7em;
	font-size: 1.1em;
}

.container-header {
	padding-top: 7em;
	padding-bottom: 3em;
}

.container-header-horizontal {
	padding-top: 1em;
	padding-bottom: 1em;
}
