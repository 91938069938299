.paper-side-drawer {
	border-radius: 20px !important;
	display: flex !important;
	visibility: visible !important;
	transform: none !important;
	transition: width 0.3s !important;
	width: 300px !important;
	height: 91.8% !important;
	margin-top: 4.5em !important;
	margin-left: 12px !important;
	background: rgba(250, 250, 250, 0.7) !important;
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.7) !important;
	border: 1px solid rgba(255, 255, 255, 0.6) !important;
	backdrop-filter: blur(15px) !important;
	-webkit-backdrop-filter: blur(15px) !important;
}

.paper-side-drawer-closed {
	margin-left: 12px !important;
	width: 68px !important;
}

.drawer-side-drawer {
	flex-shrink: 0 !important;
}

@-moz-document url-prefix() {
	.paper-side-drawer {
		background: rgba(255, 255, 255, 1) !important;
		border: rgba(255, 255, 255, 1) !important;
	}
}
