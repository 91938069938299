.group {
	background: rgba(255, 255, 255, 0.4) !important;
	border: 1px solid #e3e3e3 !important;
}

.rule {
	background-color: white;
	border: 1px solid #0000000f;
	padding: 10px;
}

.MuiButton-containedPrimary {
	color: #fff !important;
	background-color: #1d8cf8b5 !important;
}

.qb-lite .group--header:hover .group--header .group--drag-handler,
.qb-lite .group--header:hover .group--header .group--actions,
.qb-lite .group--header:not(:hover) .group--drag-handler,
.qb-lite .group--header:not(:hover) .group--actions,
.qb-lite .rule_group:not(:hover) .group--drag-handler,
.qb-lite .rule_group:not(:hover) .group--actions {
	opacity: 0.6 !important;
}

.query-builder-container > * .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorSecondary {
	color: #f50057 !important;
}
