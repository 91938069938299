.header-container-app-bar {
	position: sticky;
	top: 0;
	background: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(4px);
	align-content: center;
	padding: 0.3em 1em 0.4em 1.3em;
	border-bottom: 1px solid rgba(45, 140, 248, 0.4) !important;
	z-index: 1300 !important;
}

@-moz-document url-prefix() {
	.header-container-app-bar {
		background: rgba(255, 255, 255, 1);
	}
}
