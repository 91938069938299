.orbBackFacebook {
	z-index: -10;
	width: 7em;
	height: 6em;
	animation: shapeColorShift 14s infinite;
	filter: blur(12px);
	background: linear-gradient(-30deg, #08aeea, #527bd1, #527bd1, #27b2ff, #527bd1, #08aeea);
}

.orbBackInstagram {
	z-index: -10;
	width: 7em;
	height: 6em;
	animation: shapeColorShift 14s infinite;
	filter: blur(12px);
	background: linear-gradient(-30deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888, #f09433, #e6683c);
}

.orbBackMetaAds {
	z-index: -10;
	width: 7em;
	height: 6em;
	animation: shapeColorShift 14s infinite;
	filter: blur(12px);
	background: linear-gradient(-30deg, #08aeea, #ac60f7, #0086ff, #27b2ff);
}
