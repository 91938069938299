body {
	margin: 0px !important;
}

.background {
	position: fixed;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: linear-gradient(120deg, #030129, #2b2b43);

	&::before {
		content: '';
		position: absolute;
		width: 80%;
		height: 80%;
		background: radial-gradient(circle at top left, #2b2b43 30%, #4ecaff 110%);
		border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
		left: -20%;
		top: -20%;
		filter: blur(5px);
		animation: blobify 60s ease-in-out 0s infinite;
	}

	&::after {
		content: '';
		position: absolute;
		width: 80%;
		height: 80%;
		background: radial-gradient(circle at bottom right, #fc9b0e, #9028b8 90%);
		border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
		right: -15%;
		bottom: -30%;
		filter: blur(5px);
		animation: blobify 60s ease-in-out 0s infinite;
	}
}

@keyframes blobify {
	0% {
		border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
		transform: translateX(0) translateY(0) scale(1);
	}

	50% {
		border-radius: 30% 70% 70% 30% / 40% 60% 40% 60%;
		transform: translateX(100px) translateY(100px) scale(1.2);
	}

	100% {
		border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
		transform: translateX(0) translateY(0) scale(1);
	}
}

::-webkit-scrollbar {
	width: 11px;
	height: 11px;
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-thumb {
	background: #e1e1e1;
	border: 0px none #ffffff;
	border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
	background: #e6e6e6;
}
::-webkit-scrollbar-thumb:active {
	background: #e6e6e6;
}
::-webkit-scrollbar-track {
	background: transparent;
	border: 0px none #ffffff;
	border-radius: 29px;
}
::-webkit-scrollbar-track:hover {
	background: #616161;
}
::-webkit-scrollbar-track:active {
	background: #333333;
}
::-webkit-scrollbar-corner {
	background: transparent;
}

.aura-header {
	z-index: 2;
	position: fixed;
	top: 0;
	width: 99.5%;
	align-items: center;
	backdrop-filter: blur(10px);
	background-color: rgba(30, 30, 30, 0.25);
	border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

.aura-logo-container {
	margin: 1em;
	display: flex;
	align-items: center;
}

.aura-logo {
	height: 2.5em;
	width: 2.5em;
}

.aura-header-text {
	margin-left: 0.4em;
	font-family: Poppins;
	color: rgba(255, 255, 255, 1);
	font-size: 2em;
}

.scroll-graph-container {
	z-index: 0;
	position: sticky;
	top: 20vh;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.scroll-graph-container-left {
	z-index: 0;
	position: sticky;
	top: 20vh;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.scroll-steps-container-right {
	margin-right: 1vw;
	z-index: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.graph-container {
	height: 60vh;
	width: 60vw;
	border-radius: 25px;
	margin-right: 1vw;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
	padding-left: 1.3em;
	padding-right: 1.3em;
	padding-bottom: 1.3em;
}

.graph-container-3d {
	z-index: -2;
	height: 80vh;
	width: 60vw;
	border-radius: 25px;
	margin-left: 1vw;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
	padding: 1.2em;
}

.step-conainer-right {
	z-index: 1;
	margin-top: 10vh;
	margin-left: 1vw;
	height: 56.6vh;
	width: 30vw;
	border: 1px solid gray;
	padding: 2em;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
}

.step-conainer {
	z-index: 1;
	margin-top: 10vh;
	margin-left: 1vw;
	height: 56.6vh;
	width: 30vw;
	border: 1px solid gray;
	padding: 2em;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
}

.table-conainer {
	z-index: 1;
	margin-top: 10vh;
	margin-left: 1vw;
	height: 56.6vh;
	width: 60vw;
	border: 1px solid gray;
	padding: 1em;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
}

.big-numbers-container {
	z-index: 1;
	margin-top: 10vh;
	margin-bottom: 10vh;
	margin-left: 1vw;
	margin-right: 1vw;
	height: 100%;
	width: 50vw;
	border: 1px solid gray;
	padding: 2em;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
	overflow: auto;
}

.video-container {
	z-index: 1;
	height: 100%;
	width: 50vw;
	margin-bottom: 10vh;
	border: 1px solid gray;
	padding-left: 2em;
	padding-right: 2em;
	padding-top: 1em;
	padding-bottom: 1em;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
	overflow: auto;
}

.comment-container {
	z-index: 1;
	height: 100%;
	width: 50vw;
	border: 1px solid gray;
	padding: 2em;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
	overflow: auto;
}

.shared-voice-container {
	z-index: 1;
	margin-top: 10vh;
	margin-bottom: 10vh;
	margin-left: 1vw;
	margin-right: 1vw;
	height: 100%;
	width: 60vw;
	border: 1px solid gray;
	padding: 2em;
	border-radius: 25px;
	backdrop-filter: blur(40px);
	background-color: rgba(30, 30, 30, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.18);
	box-shadow: 0 2px 5px 0 rgba(200, 200, 200, 0.2);
	overflow: auto;
}

.big-number {
	font-family: Poppins;
	color: white;
	font-size: 6vw;
	text-align: center;
}

.big-number-label {
	font-family: Poppins;
	color: white;
	font-size: 1vw;
	text-align: center;
}

.step-index {
	font-family: Poppins;
	color: white;
	font-size: 1vw;
}

.step-content {
	font-family: Poppins;
	color: white;
	margin-top: 1vh;
	overflow: auto;
	height: 100%;
	font-size: 1.5vw;
}

.bottom-page {
	font-family: Poppins;
	margin-top: 30vh;
}

@media screen and (max-width: 1510px) {
	.graph-container {
		width: 90vw;
		height: 80vh;
		margin: auto;
	}

	.graph-container-3d {
		width: 90vw;
		height: 80vh;
		margin: auto;
		margin-right: 2.7vw;
		pointer-events: none;
	}

	.step-conainer {
		width: 80vw;
		height: 70vh;
		margin: auto;
		margin-bottom: 90vh;
		backdrop-filter: blur(5px);
		background-color: rgba(30, 30, 30, 0.4);
	}

	.aura-logo {
		height: 1em;
		width: 1em;
	}

	.aura-logo-container {
		margin: 0.5em;
	}

	.aura-header-text {
		margin-left: 0.4em;
		font-size: 1em;
	}

	.scroll-graph-container {
		top: 11vh;
	}

	.step-index {
		font-size: 1em;
	}

	.step-content {
		margin-top: 1.2vh;
		overflow: auto;
		font-size: 1.5em;
	}

	.big-number {
		font-size: 2em;
	}

	.big-number-label {
		font-size: 1em;
	}
}

@media screen and (max-width: 1000px) {
	.big-number {
		font-size: 20em;
	}

	.big-number-label {
		font-size: 20em;
	}
}
