:root {
	--black: #000000;
	--white: #ffffff;
	/*Grey*/
	--darkGrey: #7a7a7a;
	--grey: #9a9a9a;
	--lightGrey: #e9e9e9;
	--darkGrey: #afafaf;
	--lightDarkGrey: #c0c0c0;
	/*Red*/
	--red: #ff0000;
	--lighterRed: #c74243;
	/*Green*/
	--green: #4aa255;
	--green1: #12a88a;
	--lightGreen: #00f2c3;
	/*Blue*/
	--blue: #1d8cf8;
	--darkerBlue: #0a71d8;
	--lightBlue: #48a3ff;
	--babyBlue: #3daec6;
	/*Yellow*/
	--darkerYellow: #dfdf11;
	--yellow: #ffff00;
	--lightYellow: #ffff97;
	--darkYellow: #d4d400;
	/*Orange*/
	--orange: #df954b;
	/*Purple*/
	--purple: #ac60f7;
}

.color-grey {
	color: var(--grey) !important;
}
.color-blue {
	color: var(--blue) !important;
}
.color-red {
	color: var(--red) !important;
}
