/* Estilo do skeleton load */
.css-1eg0sfo-skeletonStyles-Skeleton {
	background-color: #f1f1f1 !important;
	background-image: linear-gradient(90deg, #f1f1f1, #ffffff, #f1f1f1) !important;
}
.css-1eg0sfo-skeletonStyles-Skeleton.no-margin-skeleton {
	line-height: inherit !important;
}
/* Estilo do anycharts TEM QUE COMPRAR PRA RETIRAR OFICIALMENTE */
.anychart-credits {
	display: none;
}
